import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Obfuscate from 'react-obfuscate'

//import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
        <div>
          <nav className="navigation">
            <Link to="/" className="btn"><span className="md:hidden">Home</span><span className="hidden md:block">DevinLeggett.com</span></Link>
            <div className="float-right">
              <Link to="/about" className="btn mr-2">About me</Link>
              <Obfuscate
                email="devin.leggett@gmail.com"
                className="btn"
              >Say Hi</Obfuscate>
            </div>
          </nav>
          <main>{children}</main>
          {/* <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer> */}
        </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
